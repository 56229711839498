import React from "react";
import { useBarcode } from "react-barcodes";

function Barcode({ code }) {
	const { inputRef } = useBarcode({
		value: code,
		options: {
			width: 3,
			displayValue: false
		  }
	});

	return <svg ref={inputRef} />;
}

export default Barcode;
