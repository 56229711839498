import { MainContext } from "context";
import CustomSwitch from "views/CustomSwitch";
import { useState } from "react";
import { steps } from "views/steps";


function checkUser() {
	const user = JSON.parse(localStorage.getItem("user") || "{}");
	return user.id ? user : {};
}

const App = () => {
	const [user, setUser] = useState(checkUser());
	const [step, setStep] = useState(user.id ? steps.coupon : steps.auth);
	return (
		<MainContext.Provider value={{ user, setUser, step, setStep }}>
			{CustomSwitch(step)}
		</MainContext.Provider>
	);
};

export default App;
