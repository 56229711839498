import React from "react";
import { Col, Row, Typography } from "antd";

const { Paragraph, Text } = Typography;

function CompanyInfo() {
	return (
		<Row>
			<Col xs={12} md={12} xl={12}>
				<div className="d-flex company-block-1">
					<img
						src="/img/beeline_logo.png"
						alt="logo"
						style={{
							width: "70px", height: "55px", marginLeft: '20px'
						}}
					/>
				</div>
			</Col>
			<Col xs={12} md={12} xl={12}>
				<Paragraph className="text-right mt-3">До  <Text mark>31 марта 2021 года,</Text> не упустите шанс, получить скидки от партнёров <Text strong>Beeline</Text>.</Paragraph>
			</Col>
		</Row>
	);
}

export default CompanyInfo;
