import { Divider } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import CouponView from "./CouponView";
import { Typography, Card } from "antd";
import { PhoneOutlined, ExclamationCircleOutlined, GlobalOutlined, FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

const { Title, Text, Link, Paragraph } = Typography;

function QRCodeModal({ open, company, setOpen }) {
  function handleCancel() {
    setOpen({ open: false, data: "" });
  }

  function handleOk(a) {
    setOpen({ open: false, data: "" });
  }
  function NewlineText(props) {
	const text = props.text;
	const newText = text.split('\n').map(str => <p style={{lineHeight: '17px'}}>{str}</p>);
	
	return newText;
  }

  const [ellipsis, setEllipsis] = React.useState(true);
  return (
    <Modal
      destroyOnClose={true}
      title={company.name}
      visible={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Хорошо"
      cancelText="Свернуть"
    >

      <CouponView company={company} />
      <Title level={4} className="text-center">
        {company.description}
      </Title>
      <Card
        style={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "0px",
          background: "#fffacf",
          borderColor: "#f7eb8373",
        }}
      >
        <p className="m-0" style={{ color: "#9f964c" }}>
          {company.condition}
        </p>
      </Card>
	  <Title level={4} className="text-left mt-4">
	  <ExclamationCircleOutlined /> О бренде:
      </Title>
	  <img
						alt={company.name}
						style={{ height: "40px", padding: "0px", position: 'absolute', right: '20px', marginTop: '-44px' }}
						src={company.image}
					/>
	  <div style={{padding:"10px"}}>
		<Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'Читать о бренде' } : false}>
			{company.about?.split('\n').map(str => <p>{str}</p>)}
			{/* {company.about} */}
      </Paragraph>
	  </div>

		<Divider />

	  <Title level={4} className="text-left mt-4">
	  <PhoneOutlined /> Контакты:
      </Title>
	  <div style={{padding:"10px"}}>
	  	<NewlineText text={company.adresses}/>
	  </div>
	  <div style={{padding:"10px", textAlign: "center"}}>
	  	<Link href={company.web}  target="_blank"> <GlobalOutlined style={{fontSize: '20px', marginRight: '15px'}}/></Link>
	  	<Link href={company.facebook}  target="_blank"> <FacebookOutlined style={{fontSize: '20px', marginRight: '15px'}}/></Link>
	  	<Link href={company.instagram}  target="_blank"> <InstagramOutlined style={{fontSize: '20px', marginRight: '15px'}}/></Link>
	  </div>

    </Modal>
  );
}

export default QRCodeModal;
