import mainCaller from "./mainCaller";

export function createUser({ phoneNumber, passwordFirst, passwordSecond }) {
	const user = {
		profile: {
			phoneNumber,
			firstName: phoneNumber,
			lastName: phoneNumber,
			gender: "male",
		},
	};

	if (passwordFirst) {
		user.password = {
			first: passwordFirst,
			second: passwordSecond,
		};
	}
	return mainCaller("/customer", "POST", user);
}
