import React from "react";
import { Col, Card, Button } from "antd";
import Meta from "antd/lib/card/Meta";
import { PoweroffOutlined } from "@ant-design/icons";

function CompanyRecord({ company, setModal }) {
	return (
		<Col xs={12} md={6} xl={6}>
			<Card
				style={{textAlign: "center"}}
				hoverable
				cover={
					<img
						alt={company.name}
						style={{ height: "90px", objectFit: "contain", padding: "10px 20px 0px 20px" }}
						src={company.image}
					/>
				}
			>
				<Meta title={company.name} description={company.description} />
				<Button
					type="primary"
					size="small"
					ghost
					style={{ marginTop: "30px", width: "100%" }}
					icon={<PoweroffOutlined />}
					onClick={() => {
						setModal({ open: true, data: company });
					}}
				>
					Получить
				</Button>
			</Card>
		</Col>
	);
}

export default CompanyRecord;
