import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "context";
import generateCoupon from "api/generateCoupon";
import QRCode from "react-qr-code";
import Barcode from "./Barcode";
import Loading from "components/Loading";
import { Typography } from "antd";

const { Title, Text } = Typography;

function CouponView({ company }) {
	const { user } = useContext(MainContext);
	const [loading, setLoading] = useState(true);
	const [code, setCode] = useState();

	useEffect(() => {
		generateCoupon(company, {
			phone: user.profile.phoneNumbers[0],
			lastName: user.profile.lastName,
			firstName: user.profile.firstName,
			collection: "beelinepromo",
		})
			// new Promise((res) => setTimeout(() => res("asd1544"), 1000))
			.then((code) => {
				setCode(code);
				setLoading(false);
			})
			.catch((err) => {
				setCode(false);
				setLoading(false);
			});
	}, []);
	if (loading) {
		return <Loading />;
	}

	if (!code) {
		return <h3>Ошибка, невозможно получить код</h3>;
	}
	
	return (
		<div style={{textAlign: 'center'}}>
			<Title level={3} className="text-center">Ваш код  <Text strong mark>{code}</Text></Title>
			<div style={{marginTop: '15px', marginBottom: '20px'}}><QRCode value={code} size={200} /></div>
			<div><Barcode code={code} /></div>
		</div>
	);
}

export default CouponView;
