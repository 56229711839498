import React from "react";
import { Card, Row, Col, message } from "antd";
import LoginForm from "./LoginForm";
import { steps } from "views/steps";
import ValidationForm from "./ValidationForm";
import { MainContext } from "context";
import { useContext, useState } from "react";
import { setPhoneToGetVerifyCode, verifyCode } from "api/verification";
import { createUser } from "api/user";
import Loading from "components/Loading";
const backgroundStyle = {
	backgroundImage: "url(/img/others/img-17.jpg)",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
};
const LoginOne = ({ step }) => {
	const { setUser, setStep, user } = useContext(MainContext);
	const [loading, setLoading] = useState(false);
	return (
		<div className="h-100" style={backgroundStyle}>
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col
						xs={24}
						sm={24}
						md={8}
						lg={6}
						style={{ display: "block", textAlign: "center", marginTop: "20px" }}
					>
						<Card>
							<div className="my-4">
								<div className="text-center mb-5">
									<img
										className="img-fluid"
										src="/img/beeline_logo.png"
										width="100"
										style={{ marginBottom: "20px", marginTop: "40px" }}
										alt="Beeline"
									/>
									<p>Авторизуйтесь для получения скидок от наших партнёров. Скидки доступны только для абонентов Beeline.</p>
								</div>
								{loading ? (
									<Loading />
								) : (
									<Row justify="center">
										<Col>
											{step === steps.auth ? (
												<LoginForm
													onSuccess={(phoneNumber) => {
														setLoading(true);
														setPhoneToGetVerifyCode(phoneNumber)
															.then((res) => {
																setUser({ phoneNumber });
																setStep(steps.valid);
																setLoading(false);
															})
															.catch((err) => {
																message.error("Ошибка");
															});
													}}
												/>
											) : (
												<ValidationForm
													user={user}
													onSuccess={(code) => {
														setLoading(true);
														verifyCode(user.phoneNumber, code)
															.then((res) => {
																message.success("Номер успешно подвержден");

																// create user belline db
																createUser(user)
																	.then((res) => {
																		message.success("Пользователь успешно зарегистрирован");

																		// save user data
																		localStorage.setItem(
																			"user",
																			JSON.stringify({ ...user, ...res })
																		);
																		setUser((prev) => ({ ...prev, ...res }));

																		// companies page
																		setStep(steps.coupon);
																		setLoading(false);
																	})
																	.catch((err) => {
																		message.error("Ошибка при регистрации пользователя");
																	});
															})
															.catch((err) => {
																message.error("Неверный код");
																setLoading(false);
															});
													}}
													onAgain={() => {
														console.log("again");
														setStep(steps.auth);
													}}
												/>
											)}
										</Col>
									</Row>
								)}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default LoginOne;
