import React, { useState } from "react";
import CompanyInfo from "./Header";
import { Col, Row, Input, Divider, Layout, PageHeader } from "antd";
import CompanyRecord from "./CompanyRecord";
import QRCodeModal from "./QRCodeModal";
const { Content } = Layout;
const { Search } = Input;

const companies = require("../../constants/companies.json").map((c) => ({
	...c,
	sname: c.name.toLowerCase(),
}));

let timeOut;
function Coupon() {
	const [modal, setModal] = useState({ open: false, data: {} });
	const [sortedCompany, setSortedCompany] = useState(companies);

	function searchCompanies(value) {
		if (value) {
			companies.forEach((c) => {
				c.indexSearch = c.sname.search(value.toLowerCase());
			});

			const sorted = companies
				.filter((c) => c.indexSearch > -1)
				.sort((a, b) => a.indexSearch - b.indexSearch);

			setSortedCompany([...sorted]);
		} else {
			setSortedCompany(companies);
		}
	}

	return (
		<div className="container">
			<Layout className="app-container">
				<PageHeader />

				<Content>
					<div style={{ marginBottom: "100px" }}>
						<CompanyInfo />
						<Row>
							<Col span={24}>
								<Search
									placeholder="Поиск.."
									onChange={(e) => {
										clearTimeout(timeOut);
										timeOut = setTimeout(() => {
											searchCompanies(e.target.value);
										}, 200);
									}}
									onSearch={(value) => {
										searchCompanies(value);
									}}
								/>
							</Col>
						</Row>
						<Divider />
						<Row gutter={10}>
							{sortedCompany.map((company, index) => {
								return (
									<CompanyRecord
										key={index}
										company={company}
										setModal={setModal}
									/>
								);
							})}
						</Row>
					</div>
					<QRCodeModal
						open={modal.open}
						setOpen={setModal}
						company={modal.data}
					/>
				</Content>
				<footer className="footer text-center">
					<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">Beeline Coupon</span> All rights reserved.</span>
					<span>г.Ташкент, улица Бухара 1. Контакты: <span className="font-weight-semibold"> +998901850055</span></span>
				</footer>
			</Layout>
		</div>
	);
}

export default Coupon;
