import { Form, Button, Input, message, Checkbox } from "antd";
import { useEffect, useState } from "react";

const ValidationForm = ({ onSuccess, onAgain }) => {
  const [loading, setLoading] = useState(true);

  const onFinish = (values) => {
    let number = values.code.match(/\d+/g);
    if (!number || number[0].length < 4) {
      return message.error("Wrong number");
    }
    onSuccess(number[0]);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000 * 60);
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label="Введите код"
        name="code"
        rules={[
          {
            required: true,
            message: "Введите код",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <div
        style={{
          width: "100%",
          height: "100px",
          overflow: "auto",
          border: "1px solid #dbdbdb",
          padding: "5px",
          fontSize: "12px",
          textAlign: "left",
          marginBottom: "10px",
        }}
      >
       1.	ООО «Unitel» (далее «Beeline») настоящим предоставляет лицам, акцептирующим настоящую оферту путем выполнения оговоренных ниже действий, услуг по обеспечению инфраструктурой для размещения на сайте www.beelinepromo.uz скидки от партнёров (далее “Договор”).
<br/>
<br/>
2.	Настоящий документ является публичной офертой и содержит в себе предложение о заключении договора о предоставления скидок по обеспечению инфраструктурой для размещения на сайте.
<br/>
<br/>
3.	ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.
<br/>
•	OOO «CREATIVE PEOPLE»- правообладатель Системы beelinepromo,uz предоставляющее услуги по обеспечению инфраструктурой для размещения на сайте www.beelinepromo.uz информации о партнёрах.
<br/>
•	Партнёр - юридическое лицо, предоставляющее на сайте www.beelinepromo.uz информацию о скидках.
<br/>
•	Абонент Beeline - лицо, которое может получить скидку от партнёров через сайт при предъявлении кассиру магазина купон через сайт www.beelinepromo.uz, принявшее условия настоящей публичной оферты.
<br/>
•	Система OX System- программно-аппаратный комплекс, обеспечивающий информационно - технологическое взаимодействие между партнёром и абонентом Beeline;
<br/>
•	QR-код - тип матричных штрихкодов, которую необходимо для предоставления скидки от партнёров.
<br/>
•	Авторизация- предоставление определённому лицу или группе лиц прав на выполнение определённых действий; а также процесс проверки данных прав при попытке выполнения этих действий.
<br/>
•	Акцепт- ответ лица, которому адресована оферта, о ее принятии;
<br/>
•	Оферта- предложение, адресованное одному лицу, нескольким конкретным лицам или неопределенному кругу лиц, содержащее все существенные условия договора, из которого усматривается воля лица, делающего предложение (оферента), заключить договор на указанных в предложении условиях с любым, кто отзовется. 
<br/>
<br/> 
<br/>
1.	ПРЕДМЕТ ДОГОВОРА
<br/>
<br/>
•	Договор определяет порядок размещения информации о партнёре и предоставляемых скидок для абонентов Beeline на сайте beelinepromo.uz. и является публичной офертой (далее «Оферта»), адресуемой неопределенному кругу лиц.
<br/>
•	На товары, при покупке не распространяется действие по условиям партнёра, которые были приобретены по цене выше, указанной в магазине Абонента Beeline.
<br/>
<br/>
2.	Получение скидки от партнёра.
<br/>
1.	Для получения скидки от партнёров Beeline, абоненту необходимо:
<br/>
1.	Пройти по ссылке beelinepromo.uz;
<br/>
2.	Авторизоваться по номеру:
<br/>
3.	Выбрать необходимого партнёра
<br/>
4.	Предоставить кассиру QR-код либо 7-ми значный код
<br/>
2.	Beeline не несет ответственности за содержание и достоверность информации, предоставленной Абонентом либо партнёром при получения скидки от партнёра. 
<br/>
<br/>
3.	РАССМОТРЕНИЕ ПРЕТЕНЗИЙ
<br/>
1.	ОТВЕТСТВЕННОСТЬ СТОРОН
<br/>
•	Beeline не несет ответственности:
<br/>
•	если ненадлежащее качество услуг партнёров возникло в результате ненадлежащего исполнения условий оферты Клиентом.
<br/>
•	за любые убытки, причиненные абонентом по любым причинам, в том числе в результате использования или невозможности использования Услуги, или понесенные в результате ошибок, пропусков, перерывов в работе, изменения функций, дефектов, задержек в работе Системы Beeline при передаче данных, и т.п..
<br/>
•	Beeline оставляет за собой право отказать в оказании услуг любому показателя с немедленным возвратом денежных средств если они уже оплачены, в случаи если будет считать что не в состоянии их оказать своевременно и качественно.
<br/>
2.	ФОРС МАЖОР
•	Стороны не несут ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему договору, если такое неисполнение вызвано обстоятельствами непреодолимой силы (форс-мажора), не зависящими от Сторон, в частности: стихийными бедствиями, технические неполадки, пожарами, массовыми беспорядками, революциями, военными действиями, объявлением карантина или иными бедствиями, происходящими в районах, официально признанными находящимися под влиянием вышеуказанных обстоятельств.
<br/>
3.	ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
<br/>
•	Все споры, возникающие в процессе исполнения Договора, будут в предварительном порядке рассматриваться Сторонами Договора путем переговоров.
<br/>
•	В случае не достижения согласия, споры будут рассматриваться в порядке, установленном действующим законодательством Республики Узбекистан.
<br/>
•	Во всем, что не предусмотрено настоящим договором, Стороны Договора руководствуются действующим законодательством Республики Узбекистан.
<br/>
4.	КОНФИДЕНЦИАЛЬНОСТЬ ИНФОРМАЦИИ.
<br/>
•	ООО “CREATIVE PEOPLE обязуется обеспечить безопасности персональных данных о абонентах в соответствии с законодательством Республики Узбекистан и не сообщать третьим лицам, не имеющим отношения к исполнению Договора, данные абонента без соблюдения соответствующего режима конфиденциальности.
<br/>
•	В целях заключения Договора на условиях настоящей Оферты, а также в целях надлежащего исполнения Beeline заключенного Договора c OX System, абонент Beeline подтверждает, а также лицам, уполномоченным ООО CREATIVE PEOPLE, на обработку своих персональных данных. Beeline обязуется обеспечивать конфиденциальность персональных данных.
<br/>
•	Абонент Beeline осведомлен, и согласен с тем, что в целях заключения и исполнения Договора, персональные данные могут быть переданы beelinepromo.uz.
<br/>
5.	ЗАЩИТА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ.
<br/>
•	Вся текстовая информация и графические изображения, размещенные на сайте beelinepromo.uz, являются собственностью Beeline. Использование любыми третьими лицами такой информации и/или изображений без согласия Beeline является нарушением прав Beeline и может повлечь за собой ответственность, предусмотренную действующим законодательством Республики Узбекистан.
<br/>
•	Beeline вправе в любое время вносить изменения в условия настоящей Оферты путем размещения текста настоящей Оферты в новой редакции на Сайте www.beelinepromo.uz. Изменения вступают в силу третьих лиц с даты размещения текста Оферты в новой редакции на Сайте www.beelinepromo.uz.
<br/>
•	Раздел сайта www.beelinepromo.uz 
<br/>



      </div>
      <Checkbox checked={true}>Я принимаю условия Публичной оферты</Checkbox>
      <Form.Item className="d-flex">
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          Подвердить
        </Button>
        <Button
          style={{ marginTop: "20px" }}
          type="default"
          disabled={loading}
          loading={loading}
          onClick={() => {
            onAgain();
          }}
        >
          Переслать код
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ValidationForm;
