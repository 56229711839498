import { Form, Button, Input, message } from "antd";

const tailLayout = {
	wrapperCol: {
		// offset: 8,
		// span: 16,
	},
};

const LoginForm = ({ onSuccess }) => {
	const onFinish = (values) => {
		let number = values.username.match(/\d+/g);
		if (!number || number[0].length < 9) {
			return message.error("Wrong number");
		}

		onSuccess("+998" + number[0]);
		return message.success("+998" + number[0]);
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
			<Form.Item
				// label="номер телефона"
				name="username"
				rules={[
					{
						required: true,
						message: "Введи свой номер телефона",
					},
				]}
			>
				<Input size="large" addonBefore="+998 " />
			</Form.Item>

			<Form.Item className="m-auto" {...tailLayout}>
				<Button className="m-auto" type="primary" htmlType="submit">
					Войти
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LoginForm;
