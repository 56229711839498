import axios from "axios";
const { security_key, subdomain } = require("../config/market");

export default function mainCaller(path, method, data) {
	const options = {
		url: `https://${subdomain}.ox-sys.com/market-api${path}`,
		headers: {
			"Content-type": "application/json",
			"security-key": security_key,
		},
		method,
	};

	if (data) {
		options.data = JSON.stringify(data);
	}

	return axios(options).then((r) => r.data);
}
