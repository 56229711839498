import { steps } from "views/steps";
import LoginOne from "views/auth/Login";
import Coupon from "views/coupon/Coupon";

export default function CustomSwitch(step) {
	switch (step) {
		case steps.auth:
		case steps.valid:
			return <LoginOne step={step} />;
		case steps.coupon:
			return <Coupon />;
		default:
			return "Empty";
	}
}
