import axios from "axios";

export default function generateCoupon(company, userData) {
	return axios({
		url: `https://${company.subdomain}.ox-sys.com/coupons/beeline/code-generate`,
		headers: {
			"Content-type": "application/json",
			"auth-token": company.auth_token,
		},
		method: "POST",
		data: JSON.stringify(userData),
	}).then((r) => r.data);
}
